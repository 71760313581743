<template>
  <div class="nHome-detail" v-if="info">
    <van-nav-bar title="Thông tin chi tiết" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="detail-title"> {{ info.xuanfei_name }}</div>
    <div style="display: flex;">

      <div class="detail-tabs" v-for="(item, index) in tabs" :class="index == tabsIndex ? 'active' : ''" @click="tabsIndex = index" :key="index">

        <img class="detail-tabs-ico" v-if="item == 'info' && tabsIndex != 0" src="/images/nHome/ico-info-active.png" alt="">
        <img class="detail-tabs-ico" v-if="item == 'info' && tabsIndex == 0" src="/images/nHome/ico-info.png" alt="">
        <img class="detail-tabs-ico" v-if="item == 'images' && tabsIndex != 1" src="/images/nHome/ico-img-active.png" alt="">
        <img class="detail-tabs-ico" v-if="item == 'images' && tabsIndex == 1" src="/images/nHome/ico-img.png" alt="">

        {{ item }} <template v-if="item == 'images'">({{ info.img_url.length }})</template> 
      </div>
    </div>
    <div class="detail-content">
      <template v-if="tabsIndex == 0">
        <div class="movie-list-n-img">
          <!-- <img class="movie-list-n-img" :data-src="info.img" alt="" v-lazy="info.img"> -->
                <van-image
                  fit="cover"
                  :src="info.img_url[0]"
                  class="movie-list-n-img" :data-src="info.img_url[0]"  alt=""  v-lazy="info.img_url[0]"
                />
          <div class="movie-list-n-lab">{{ info.addres2 }}</div>
        </div>

        <div class="movie-list-n-item-bottom">
          <div class="movie-n-time-div">
            <img class="movie-list-ico-loves" v-for="item in 6" :key="item" src="/images/nHome/ico-love.png" alt="">

            <div class="movie-list-money">
              <img class="movie-list-ico-money" style="width:20px" src="/images/nHome/ico-money.png" alt="">{{ info.price }}
            </div>
            <!-- <div class="movie-list-money">
              <img class="movie-list-ico-money" style="width:12px" src="/images/nHome/ico-phone.png" alt="">{{ info.phone }}
            </div> -->
            <div class="movie-list-addr">
              <img class="movie-list-ico-addr" src="/images/nHome/ico-addr.png" alt="">
              {{ info.address }}
            </div>
            <div class="movie-list-notice">
              <img class="movie-list-ico-notice" src="/images/nHome/ico-time.png" alt="">
              {{ info.attributes[56]}}
            </div>
          </div>
        </div>
        <div class="table">
          <div class="table-list" v-for="(item, index) in tableList" :key="index">
            <div class="name">{{ item.nameTxt }}</div>
            <div class="content">{{ info.attributes[item.id] }}</div>
          </div>
        </div>
      </template>
      <template v-if="tabsIndex == 1">
        <div class="tabs1">
          <!-- <img class="tabs1-img" :data-src="item" alt="" v-lazy="item"> -->
          <van-image
                  fit="cover"
                  v-for="(item, index) in info.img_url" @click="openImg(index)" :key="index"
                  :src="item"
                  class="tabs1-img" :data-src="item" alt="" v-lazy="item"
                />
        </div>
      </template>

    </div>
  </div>
</template>


<script>
import { ImagePreview } from 'vant';

import dayjs from 'dayjs'
import tableList from './table';
export default {
  data() {
    return {
      btnLogin: false,
      info: [],
      tableList,
      tabsIndex: 0,
      tabs: ['info', 'images'],
      dayjs
    };
  },
  created() {
    this.$http({
      method: 'get',
      url: 'xuanfeidata',
      data: { id: this.$route.query.id }
    }).then(res => {
      console.log(res.data)
      this.info = res.data
    })

  },
  methods: {
    openImg(index) {
      let that = this;
      ImagePreview({
        images: that.info.img_url,
        startPosition: index,
      });
    },
    back() {
      if (this.url) {

        this.$router.replace({ path: '/Mine' })
      } else {
        window.history.back();
      }
    },

  }
};
</script>


<style scoped lang="less">
.nHome-detail {
  background: #f2f2f5;
  height: 100vh;
  overflow: auto;
  color: #000;
}

.movie-list-n-img {
  width: 750px;
  height: 750px;
  position: relative;
}

.movie-list-n-lab {
  display: inline;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  background-color: #f905e5;
  width: 315px;
  overflow: hidden;
}

.movie-list-ico-loves {
  display: inline-block;
  width: 40px;
}


.movie-list-notice {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-ico-notice {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

.movie-list-addr {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-ico-addr {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

.movie-list-money {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-ico-money {
  width: 30px;
  display: inline-block;
  margin-right: 10px;

}

.detail-title {
  font-size: 40px;
  margin: 20px 0;
}

.detail-tabs {
  color: #e74c3c;
  font-size: 30px;
  flex: 1;
  padding: 40px 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.detail-tabs.active {
  color: #f905e5;
}

.detail-tabs-ico {
  width: 30px;
  margin-right: 10px;
}

.movie-list-n-item-bottom {
  padding: 30px;
}

.tabs1 {
  display: flex;
  flex-wrap: wrap;
}

.tabs1-img {
  width: 50%;
  padding: 10px;
}

.table {

  // padding: 30px 0;
  .table-list {

    padding: 20px 30px;
    display: flex;
    background: #eee;

    .name {
      width: 30%;
      display: flex;
      align-items: center;
    }

    .content {
      width: 70%;
    }
  }

  .table-list:nth-child(2n) {
    background: #999;
  }
}

.table-title {
  padding: 30px;
  font-size: 36px;
  background: #ccc;
  border-bottom: 1px solid #eee;
}

.comment {
  padding: 30px;
}

.commentList {}

.commentList-content {
  display: flex;
  // align-items: center;
  margin-bottom: 30px;
  font-size: 28px;
}

.commentList-content-r {
  margin-left: 20px;
  display: flex;
  // align-items: center;
}

.commentList-content-avatar {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.commentList-content-name {
  margin-bottom: 10px;
  color: #f905e5;
  font-weight: bold;
}

.commentList-content-txt {}


.commentList-content-bottom{
  display: flex;
  align-items: center; justify-content: space-between;
  margin-top: 10px;
}
.commentList-content-left{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f905e5;
}
.commentList-content-hf{
  margin-right: 10px;
  width: 34px;
}
.commentList-content-time{

}
.reply{
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
.inp{
  background: #999;
  margin-right: 10px;
  border-radius: 30px;
  padding: 10px 20px;
  width: 100%;
  border: none;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
.btn{
display: inline-block;
padding: 10px 15px;
margin-bottom: 0;
font-size: 22px;
font-weight: 400;
line-height: 1.42857143;
text-align: center;
white-space: nowrap;
vertical-align: middle;
-ms-touch-action: manipulation;
touch-action: manipulation;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
background-image: none;
border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
border-radius: 4px;color: #d6d6be;
background-color: #7c1022;
border-color: #7c1022;
}
}
</style>
