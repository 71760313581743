<template>
  <div class="container page">
    <van-nav-bar title="Lịch sử làm nhiệm vụ" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-empty v-if="list.length === 0" description="Không có dữ liệu!" />
        <div v-else class="item_list" v-for="(v,key) in list" :key="key">
          <div class="lottery_info">
            <van-image class="cover" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <span class="period-number">{{v.expect}}</span>
            <span class="period-number">{{v.name}}</span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">
              <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "Yêu Thích 1" : "Yêu Thích 2"}}</span>
              <span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? "Yêu Thích 3" : "Yêu Thích 4"}}</span>
            </div>
          </div>
          <div class="topInfo">
            <span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
            <span v-else>{{v.status_text}}</span>
            <span>Đã đặt：{{Number(v.money || 0).toLocaleString("us-UK")}}</span>
          </div>
          <div class="time">
            <span>Thời gian đặt：{{v.create_time}}</span>
          </div>
          <div class="time">
            <span>Lựa chọn：
              <span v-for="(type, index) in v.types" :key="index">{{typeToLabel(type)}}<span v-if="index < v.types.length - 1">, </span></span>
            </span>
          </div>		  
          <div class="time">
            <span>Thời gian hoàn tất: {{v.update_time}}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[],
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast('Thành công!');
        this.isLoading = false;
      }, 500);
    },
    getUserGameList(){
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res=>{
        if(res.code === 200){
			console.log(res.data)
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    typeToLabel(type) {
      const labels = {
        1: 'Yêu Thích 1',
        2: 'Yêu Thích 2',
        3: 'Yêu Thích 3',
        4: 'Yêu Thích 4'
      };
      return labels[type] || type; // Return the label or the type itself if not found
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}
.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #2c3e50;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .cover{
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}
.item_list  .period-number{
  margin-left: 50px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #2c3e50;
}
.item_list .lottery_info{
  display: flex;
}
.recent {
  display: flex;
  align-items: center;
  height: 100px;
}
.kuaisan-ball .left{
  justify-content: flex-start;
}
.kuaisan-ball{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kuaisan-ball .res-img{
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #2c3e50;
}
.kuaisan-ball .res-des.middle{
  width: 30%;
  /*font-size: 18px;*/
  border: 1px solid #9b9b9b;
  border-radius: 10px;
  margin-left: 10px;
}
</style>
