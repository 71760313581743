<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="Bạn muốn hẹn hò?" />
		<div class="convention-item">			
			<van-tabs animated sticky  :swipeable="true">
				<van-tab title="Thành phố">
					<div class="card">Cộng đồng HENYEU uy tín hàng đầu, nơi chia sẻ thông tin các em gái uy tín nhất hiện nay cho anh em. Chúng tôi cam kết mang đến một nơi uy tín, lành mạnh và đảm bảo riêng tư cho khách hàng.</div>
					<div class="mx-2 mt-4">
						<div class="grid grid-cols-2 gap-2">
							<div v-for="(val, key) in addlist" :key="key">
								<div class="linear-gradient flex h-10 items-center justify-center rounded-md text-white hover:cursor-pointer" v-for="(v, k) in val" :key="k" @click="addgo(v)">
									{{ v.name }}
								</div>
							</div>
						</div>
					</div>
				</van-tab>				
				<van-tab title="Quy trình và giá">
					<div class="card">Trang web này cung cấp các dịch vụ liên quan đến ngoại vi, đồng hành kinh doanh và cảm xúc trong cùng một thành phố. Để bảo vệ quyền riêng tư của từng người dùng, khách hàng chỉ được tham gia thông qua việc liên hệ với nhân viên tiếp đãi hoặc thành viên cấp cao của nền tảng này.</div>
					<div class="rig-box">
						<p class="rig-title">Có những nguồn tài nguyên nào?</p>
						<p class="rig-content">Có thể tìm thấy các nghệ sĩ nổi tiếng, người mẫu, tiếp viên hàng không, người mẫu trẻ, sinh viên đại học, không có điều gì là không thể với nền tảng này</p>
						<p class="rig-title">Phạm vi dịch vụ?</p>
						<p class="rig-content">Hẹn hò miễn phí trong cùng một thành phố, tất cả các địa điểm trên toàn quốc, có mặt tại các thành phố hàng đầu trong nước, cũng có thể sắp xếp thông qua việc liên hệ với nhân viên tiếp đãi.</p>
						<p class="rig-content">Trang web này cung cấp các dịch vụ liên quan đến ngoại vi, đồng hành kinh doanh và cảm xúc trong cùng một thành phố. Để bảo vệ quyền riêng tư của từng người dùng, khách hàng chỉ được tham gia thông qua việc liên hệ với nhân viên tiếp đãi hoặc thành viên cấp cao của nền tảng này.</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				addlist: [
				]
			};
		},
		methods: {
			addgo(data) {
				if (!localStorage.getItem('token')) {
					this.$router.push({ path: '/Login' });
				} else {
					this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
				}
			},
			getAddress() {
				this.$http({
					method: 'get',
					url: 'address_list'
				}).then(res => {
					this.addlist = res.data;
				});
			}
		},
		created() {
			this.getAddress();
		}
	};
</script>

<style lang="less" scoped>
	.page {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: #f2f2f5;
	}

	.van-nav-bar {
		line-height: 50px;
	}

	::v-deep .van-nav-bar__title {
		max-width: 60%;
		margin: 0 auto;
		color: #ffffff;
		font-size: 35px;
	}

	::v-deep .van-nav-bar__content {
		height: 100px;
	}

	.van-sidebar {
		width: 180px;
	}

	.convention-hall {
		display: flex;
		flex-direction: column;
		bottom: 20px;
		background: #f2f2f5;
	}

	::v-deep .van-tab {
		font-size: 30px;
		line-height: 100px;
		font-weight: bold;
	}

	::v-deep .van-tabs__line {
		background-color: #7e5678;
	}

	::v-deep .van-tabs--line .van-tabs__wrap {
		height: 100px;
	}

	::v-deep .van-tabs__wrap--scrollable .van-tab {
		padding: 0 23px;
	}

	.card {
		background-color: #ab7037;
		padding: 0.625rem;
		width: 95%;
		color: white;
		font-size: 0.8125rem;
		margin: 0.625rem auto;
		border-radius: 0.375rem;
	}

	::v-deep .van-row--flex {
		height: 80px;
		line-height: 80px;
	}

	.rig-box {
		width: 95%;
		margin: 0.625rem auto;
	}

	.rig-title {
		color: #0bdab0;
		font-size: 1.125rem;
	}

	.rig-content {
		font-size: 20px;
		// margin-top: 10px;
	}

	.address {
		width: 90%;
		margin: 0 auto;
	}
    .gap-2 {
        gap: 0.5rem;
    }

    .grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr));
    }

    .grid {
        display: grid;
    }

    .mt-4 {
        margin-top: 1rem;
    }

    .mx-2 {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
    }

    .rounded-md {
        border-radius: 0.375rem;
    }

    .justify-center {
        justify-content: center;
    }

    .items-center {
        align-items: center;
    }

    .h-10 {
        height: 2.5rem;
    }

    .flex {
        display: flex;
    }

    .header, .linear-gradient {
        background: linear-gradient(270deg, #ac6015, #e6c3a1);
    }
</style>
